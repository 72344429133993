.footer_bg {
    background: url(../images/footer_bg.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}
.footer .social-icon {
    margin-top: -28px;
}
.footer-box #newsletterEmail {
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}
.footer-box button {
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}

.footer-link {
    padding-left: 3rem;
}
.newtheme #newsletterEmail {
    font-family: Sora;
    border: 1px solid #686666;
    background: transparent;
    color: #fff;
    font-size: 14px;
    padding: 0px 10px;
    width: 100%;
    height: 50px;
    border-radius: 4px;
}
.newtheme .btn-submit {
    background-color: #ab5fff;
    background:linear-gradient(90deg, #05D6D9 0.3%, #F907FC 99.65%);
    font-family: Sora;
    height: 50px;
    font-size: 14px;
    border-radius: 4px;
    padding: 0px 12px;
}

.newtheme .social-icon ul li {
    display: inline;
    margin: 0px 4px;
    /* line-height: 4; */
    border-radius: 50%;
    padding: 8px 0px 6px 0px;
    height: 26px;
    background: linear-gradient(90deg, #05D6D9 0.3%, #F907FC 99.65%);
}
.newtheme .social-icon ul li img {
    padding: 0px 10px;
    width: 35px;
    vertical-align: unset;
    filter: brightness(0) invert(1);
    /* height: 33px; */
    border-radius: 5px;
}
.footer-scroll {
    background: #000;
    position: relative;
    z-index: 1;
}
.footer-scroll .scroll-to-top {
    position: absolute;
    background-color: white;
    right: 0px;
    left: 0;
    bottom: 40px;
    top: -25px;
    z-index: 2;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
}
.footer-scroll .scroll-to-top {
    /* background: url(../images/uparrow.png); */
    background-size: contain;
    background-repeat: no-repeat;

}
.footer-scroll .scroll-to-top::before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    z-index: -1;
    border-radius: inherit;
    background: #090e19;
}
.footer-scroll .scroll-to-top svg{
    animation: jumpInfinite 1.5s infinite;
    height: 15px;
    width: 15px;
}

@keyframes jumpInfinite {
    0% {
      margin-bottom: 0;
    }
    50% {
      margin-bottom: 8px;
    }
    100% {
      margin-bottom: 0;
    }
  }